var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: { name: _vm.name },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Подтверждение переноса:")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("time-left"),
      _c(
        "el-row",
        { class: [{ "flex-w": _vm.isMobile }, "btn-container"] },
        [
          _c(
            "iq-title",
            {
              class: {
                desktopHeader: !_vm.isMobile,
                mobileHeader: _vm.isMobile,
              },
            },
            [_vm._v(" Ближайший доступный тайм слот: ")]
          ),
          _c("div", { staticClass: "label-date" }, [
            _vm._v(
              " " +
                _vm._s(
                  `${_vm.dateFormat.date} c ${_vm.dateFormat.from} до ${_vm.dateFormat.to}`
                ) +
                " "
            ),
            _vm.dateFormat.note
              ? _c("span", { staticClass: "label-date__note" }, [
                  _vm._v(" (" + _vm._s(_vm.dateFormat.note) + ") "),
                ])
              : _vm._e(),
            _vm._v(" : "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          _c(
            "iq-button",
            {
              attrs: { color: "main-dark", size: "fullWidth" },
              on: { onClick: _vm.setTimeslotTime },
            },
            [
              _c("i", { staticClass: "el-icon-check button-icon" }),
              _vm._v(" Подтвердить перенос "),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "22px", width: "100%" } },
            [
              _c(
                "iq-button",
                {
                  attrs: { color: "gray", size: "fullWidth" },
                  on: { onClick: _vm.closeModal },
                },
                [_vm._v(" Отказаться от переноса ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }