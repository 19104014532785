var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "timer-slots" }, [
    _c("i", {
      staticClass: "el-icon-warning",
      staticStyle: { color: "#db6d39", "margin-right": "12px" },
    }),
    _c("div", { staticClass: "timer-text" }, [
      _vm._v("Осталось на подтверждение:"),
    ]),
    _c("span", [_vm._v(" " + _vm._s(_vm.endTime) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }