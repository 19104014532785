<template>
  <div class="timer-slots">
    <i class="el-icon-warning" style="color: #db6d39; margin-right: 12px" />
    <div class="timer-text">Осталось на подтверждение:</div>
    <span> {{ endTime }} </span>
  </div>
</template>

<script>
import {
  DR_GET_CURRENT_TIME,
  DR_UPDATE_RUN_TIMER,
  DR_UPDATE_SLOAT_PRICE_DATA,
} from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TimeLeft',
  computed: {
    ...mapGetters({
      currentTimeState: DR_GET_CURRENT_TIME,
    }),
    endTime() {
      if (this.currentTimeState <= 1) {
        this.updateRunTime(false)
        this.updateSloatData()
      }

      return this.display(this.currentTimeState)
    },
  },
  methods: {
    ...mapActions({
      updateSloatData: DR_UPDATE_SLOAT_PRICE_DATA,
      updateRunTime: DR_UPDATE_RUN_TIMER,
    }),

    display(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const hours = seconds / 3600
      const minutes = (seconds % 3600) / 60

      return [hours, minutes, seconds % 60].map(format).join(':')
    },
  },
}
</script>

<style lang="sass">
$mainFont: #606266
$mainBg: #FDF6EC
$borderBg: #FAECD8

.timer-text
  text-align: left
  line-height: 22px
  font-weight: normal
  font-size: 14px
  width: 100%
  padding: 0 !important
  color: $mainFont

.timer-slots
  padding: 8px 15px
  background: $mainBg
  border: 1px solid $borderBg
  border-radius: 4px
</style>
